import { DynamicObject } from '../../utils/commonInterfaces';
import { CustomField } from '../common/types';
import { Role } from '../roles/types';
import { User } from '../users/types';
export enum CompaniesActionTypes {
    SET_COMPANY_LOADING_DATA = '@@companies/SET_COMPANY_LOADING_DATA',
    GET_USER_COMPANIES_REQUEST = '@@companies/GET_USER_COMPANIES_REQUEST',
    GET_USER_COMPANIES_SUCCESS = '@@companies/GET_USER_COMPANIES_SUCCESS',
    SELECT_USER_COMPANY_REQUEST = '@@companies/SELECT_USER_COMPANY_REQUEST',
    SELECT_USER_COMPANY_SUCCESS = '@@companies/SELECT_USER_COMPANY_SUCCESS',
    SAVE_USER_COMPANY_REQUEST = '@@companies/SAVE_USER_COMPANY_REQUEST',
    SAVE_USER_COMPANY_RESPONSE = '@@companies/SAVE_USER_COMPANY_RESPONSE',
    UPLOAD_COMPANY_LOGO_REQUEST = '@@companies/UPLOAD_COMPANY_LOGO_REQUEST',
    GET_SYSTEM_CONFIGURATION_SUCCESS = '@@companies/GET_SYSTEM_CONFIGURATION_SUCCESS',
    SAVE_COMPANY_ATB_SETTINGS_REQUEST = '@@companies/SAVE_COMPANY_ATB_SETTINGS_REQUEST',
    RESET_ALL_COMPANY_DATA_TABLE_FILTERS = '@@companies/RESET_ALL_COMPANY_DATA_TABLE_FILTERS',
    GET_COMPANY_WORKFLOW_OPTIONS_REQUEST = '@@companies/GET_COMPANY_WORKFLOW_OPTIONS_REQUEST',
    GET_COMPANY_WORKFLOW_OPTIONS_SUCCESS = '@@companies/GET_COMPANY_WORKFLOW_OPTIONS_SUCCESS',
}

export interface CompaniesState {
    readonly loading: boolean;
    readonly userCompanies: CompanyUserRole[];
    readonly selectedUserCompany: CompanyUserRole | undefined;
    readonly saveLoading: boolean;
    readonly systemConfiguration: SystemConfiguration | undefined;
    readonly workflowOptions: WorkflowOption[];
    readonly fullWorkflowOptions: WorkflowOption[];
    readonly workflowFetchLoading: boolean;
}

export interface WorkflowOption {
    readonly WorkflowId: string;
    readonly WorkflowName: string;
    readonly Type: string;
}

export interface SystemConfiguration {
    readonly Environment: string;
    readonly LiveMode: boolean;
}

export interface Company {
    readonly CompanyId: string;
    readonly OrganisationId: string;
    readonly Name: string;
    readonly Email: string;
    readonly LogoPath: string;
    readonly CreatedDateTime: string;
    readonly UpdateDateTime: string;
    readonly AddressLine1: string;
    readonly AddressLine2: string;
    readonly Suburb: string;
    readonly State: string;
    readonly Postcode: string;
    readonly PhoneNumber: string;
    readonly ABN: string;
    readonly Timezone: string;
    readonly Industry: string;
    readonly PrimaryColorTheme: string;
    readonly SecondaryColorTheme: string;
    readonly CompanyContacts: CompanyContact[];
    readonly CustomFieldsConfigure: CompanyCustomFieldConfigure[];
    readonly IsDueDateView: boolean;
    readonly IsCalendarView: boolean;
    readonly IsSearchCustomFieldsByCheckbox: boolean;
    readonly UsingCustomerWorkflow: boolean;
    readonly LockedDeliveryMethod: boolean;
    readonly Region: string;
    readonly LanguagePackage: LanguagePackage;
    readonly PaymentOptions: DynamicObject;
    readonly ImportType: string;
    readonly CloudImportType: string;
    readonly ApiImportType: string;
    readonly EnableExternalUserTaskAssignment: boolean;
    readonly UsingMultipleWorkflow: boolean;
    readonly CustomerTitleLabel: string;
    readonly Country: string;
    readonly IsClosed?: boolean;
    readonly TotalUser?: number;
    readonly SupportSendNewInvoice: boolean;
    readonly SupportCashAllocation: boolean;
    readonly NotificationCustomization: CompanyNotificationCustomization;
    readonly EnableMultiFactorAuthentication: boolean;
    readonly InboundEmail?: string;
    readonly EnableBankFileImporting: boolean;
    readonly UsingCustomBankFileProcessor: boolean;
}

export interface CompanyCustomFieldConfigure {
    Type: string;
    Number: number;
    FieldName: string;
    CustomFieldValues?: CustomField[];
    children?: CustomField[];
    DisplayInNotification: boolean;
}

export interface CompanyUserRole {
    readonly CompanyUserRoleId: string;
    readonly User: User;
    readonly Role: Role;
    readonly Company: Company;
    readonly CreatedDateTime: string;
    readonly UpdateDateTime: string;
}

export interface CompanyContact {
    readonly Name: string;
    readonly PhoneNumber: string;
    readonly Email: string;
    readonly Type: string;
}

export interface LanguagePackage {
    Language: string;
    Suburb: string;
    State: string;
    Postcode: string;
    CompanyRegistrationNumber: string;
    BankBSB: string;
    BankAccountNumber: string;
    DateFormat: string;
    CurrentDateFormat: string;
}

export interface CompanyNotificationCustomization {
    BrandingLocation?: string;
    OpeningWording?: string;
    IncludeLinkInLetterBlock?: boolean;
    InformUsInstruction?: string;
    InformUsTextLabel?: string;
    CreditUsageWording?: string;
    CardPaymentCustomization: CardPaymentCustomization;
    MailingChequePaymentCustomization: MailingChequePaymentCustomization;
    BankPaymentCustomization: BankPaymentCustomization;
}

export interface CardPaymentCustomization {
    Heading: string;
    Description: string;
}

export interface MailingChequePaymentCustomization {
    Heading: string;
    Description: string;
}

export interface BankPaymentCustomization {
    Heading: string;
    Description: string;
}