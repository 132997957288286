/**
 * File responsible for the content when clicking `Allocate to invoice` in credits management pages.
 */

import { Drawer } from 'antd';
import React, { } from 'react';
import { BankFileDescriptorVM } from '../../store/bankFileDescriptors/types';
import CreateBankFileDescriptorItemDrawerContentWrapper from './CreateBankFileDescriptorDrawerContent';

interface IProps {
    readonly visible: boolean;
    readonly closePanel?: (refetchList?: boolean) => void;
    readonly form?: any;
    readonly isEditMode: boolean;
    readonly editBankFileDescriptor?: Partial<BankFileDescriptorVM>;
    readonly handleAddBankFileDescriptor?: (bankFileDescriptor: BankFileDescriptorVM) => void;
    readonly handleEditBankFileDescriptor?: (bankFileDescriptor: BankFileDescriptorVM) => void;
    readonly existingBankFileDescriptorList?: Partial<BankFileDescriptorVM>[];

}

const CreateBankFileDescriptorItemPanel: React.FC<IProps> = ({
    visible,
    closePanel,
    form,
    isEditMode,
    editBankFileDescriptor,
    handleAddBankFileDescriptor,
    handleEditBankFileDescriptor,
    existingBankFileDescriptorList
}: IProps) => {

    /**
     * Function called when `Cancel` button is clicked inside Reconcile report panel.
     */
    const handleClosePanel = () => {
        if (closePanel) closePanel();
    };

    return (
        <Drawer
            title={`${isEditMode ? 'Edit' : 'Add'} Descriptor`}
            width={700}
            onClose={handleClosePanel}
            visible={visible}
            className="add-bank-file-descriptor-item-panel"
            closable={false}
            maskClosable={false}
        >
            <CreateBankFileDescriptorItemDrawerContentWrapper
                visible={visible}
                isEditMode={isEditMode}
                closePanel={handleClosePanel}
                editBankFileDescriptor={editBankFileDescriptor}
                handleAddBankFileDescriptor={ handleAddBankFileDescriptor }
                handleEditBankFileDescriptor={ handleEditBankFileDescriptor }
                existingBankFileDescriptorList={ existingBankFileDescriptorList }
            />
        </Drawer>
    );
};

export default CreateBankFileDescriptorItemPanel;